import { SiteHeader } from '@squareup/dex-svelte-global-nav';
import { SiteHeader as SsrSiteHeader } from '@squareup/dex-svelte-global-nav/dist/ssr';
import { NullableClassName } from '@squareup/dex-types-shared-ui';
import { Box } from '@squareup/dex-ui-shared-base';
import {
  convertToEnvironmentUrl,
  getEnvironmentName,
  getNextPublicBaseURL,
  getNextPublicSquareURL,
} from '@squareup/dex-utils-environment';
import { usePathname } from '@squareup/dex-utils-shared-routing-hooks';
import React, { FunctionComponent, useRef } from 'react';

import { SvelteComponent } from '../SvelteComponent';

interface HeaderProps {
  onSearchClicked?: () => void;
  merchantName: string;
  employeeName: string;
  isSignedIn: boolean;
  roleName: string;
  showSwitchBusiness: boolean;
  onHamburgerMenuClicked?: () => void;
  displaySearch: boolean;
  useHamburgerMenu: boolean;
  showSearchBarHotkey: boolean;
  shouldNavHandleSearchKeyPress: boolean;
  slim: boolean;
  showMobilePaymentsSDKLinks?: boolean;
}

const permissionRoleMap = new Map([
  ['DEVELOPER_ADMINISTRATOR', 'Administrator'],
  ['DEVELOPER', 'Developer'],
  ['CONTENT_CONTRIBUTOR', 'Content contributor'],
  ['READ_ONLY', 'Read-only'],
  ['MERCHANT_OWNER', 'Owner'],
]);

export const Header: FunctionComponent<HeaderProps & NullableClassName> = ({
  className,
  ...rest
}) => {
  const { pathName, isReady } = usePathname();
  const siteHeaderProps = getProps(rest, isReady ? pathName : '');
  const headerRef = useRef<SiteHeader>();

  const Children = () => (
    <SvelteComponent
      ref={headerRef}
      clientSideComponent={SiteHeader}
      // @ts-ignore: Unreachable code error
      serverSideComponent={SsrSiteHeader}
      componentProps={siteHeaderProps}
    ></SvelteComponent>
  );

  // Svelte components do not accept classnames so for now add a div when needed.
  return className ? (
    <Box className={className}>
      <Children />
    </Box>
  ) : (
    <Children />
  );
};

function getProps(props: HeaderProps, currentPath: string) {
  const consoleBasePath = '/console';
  const env = getEnvironmentName();
  const publicSquareURL = getNextPublicSquareURL();
  const developerSquareURL = getNextPublicBaseURL();
  const returnURL = currentPath.startsWith('/explorer')
    ? developerSquareURL + currentPath
    : `${developerSquareURL}/apps`;
  return {
    merchantName: props.merchantName,
    employeeName: props.employeeName,
    displaySearch: props.displaySearch,
    eventCallback: () => null,
    highlightMenu: currentPath.startsWith(consoleBasePath) ? 'Account' : 'Docs',
    homeLink: `${developerSquareURL}`,
    isSignedIn: props.isSignedIn,
    onHamburgerMenuClicked: props.onHamburgerMenuClicked,
    onSearchClicked: props.onSearchClicked,
    signInLink: `${publicSquareURL}/login?app=developer&return_to=${returnURL}`,
    signOutLink: `${publicSquareURL}/logout?return_to=${developerSquareURL}${currentPath}`,
    slim: props.slim,
    noPadding: true,
    useHamburgerMenu: props.useHamburgerMenu,
    squareUrl: (url: string) => convertToEnvironmentUrl(url, env),
    permissionSetName: friendlyPermissionRoleName(props.roleName),
    showSwitchBusiness: props.showSwitchBusiness,
    showSearchBarHotkey: props.showSearchBarHotkey,
    shouldNavHandleSearchKeyPress: props.shouldNavHandleSearchKeyPress,
    showGraphqlExplorer: true,
    showMobilePaymentsSDKLinks: props.showMobilePaymentsSDKLinks,
  };
}

function friendlyPermissionRoleName(roleName: string) {
  return permissionRoleMap.get(roleName) || roleName;
}
